import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import loadable from "@loadable/component";
import ProtectedRoute from "./routes/ProtectedRoute";

const ManageUsers = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.ManageUsers,
});
const ManageObjects = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.ManageObjects,
});
const ManageCards = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.ManageCards,
});
const ReviewHistory = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.ReviewHistory,
});
const Account = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.Account,
});
const Objects = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.Objects,
});
const ObjectDetails = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.ObjectDetails,
});
const PrintStatistics = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.PrintStatistics,
});
const QRCodePrint = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.QRCodePrint,
});
const Login = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.Login,
});
const Review = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.Review,
});
const PageNotFound = loadable(() => import("./pages"), {
  resolveComponent: (components) => components.PageNotFound,
});

function App() {
  const currentLoggedUser = useSelector((state) =>
    state.user?.isActive === "True" ? true : false
  );

  return (
    <Router>
      <Routes>
        {localStorage.jwtToken ? (
          <Route path="*" element={<Navigate to="/account" />} replace />
        ) : (
          <Route path="*" element={<Navigate to="/login" />} replace />
        )}
        <Route path="/page-not-found" element={<PageNotFound />} exact />
        <Route path="/login" element={<Login />} exact />
        <Route path="/review/:cardHash" element={<Review />} exact />
        <Route path="/object/qrcode/:locationHash" element={<QRCodePrint />} exact />
        <Route
          path="/manage-users"
          element={<ProtectedRoute isLogged={currentLoggedUser} component={<ManageUsers />} />}
        />
        <Route
          path="/manage-objects"
          element={<ProtectedRoute isLogged={currentLoggedUser} component={<ManageObjects />} />}
        />
        <Route
          path="/manage-cards"
          element={<ProtectedRoute isLogged={currentLoggedUser} component={<ManageCards />} />}
        />
        <Route
          path="/review-history"
          element={<ProtectedRoute isLogged={currentLoggedUser} component={<ReviewHistory />} />}
        />
        <Route
          path="/account"
          element={<ProtectedRoute isLogged={currentLoggedUser} component={<Account />} />}
        />
        <Route
          path="/objects"
          element={<ProtectedRoute isLogged={currentLoggedUser} component={<Objects />} />}
        />
        <Route
          path="/object/:locationId"
          element={<ProtectedRoute isLogged={currentLoggedUser} component={<ObjectDetails />} />}
        />
        <Route
          path="/object/print-statistics/:locationId"
          element={<ProtectedRoute isLogged={currentLoggedUser} component={<PrintStatistics />} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
