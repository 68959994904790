import AxiosInstance from "./AxiosInstance";

const setAuthorizationToken = (token) => {
  if (token) {
    AxiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete AxiosInstance.defaults.headers.common["Authorization"];
  }
};
export default setAuthorizationToken;
