import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import store from "./store/store";
import * as actionTypes from "./store/actionTypes";
import jwt from "jwt-decode";
import setAuthorizationToken from "./api/Auth";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  const decryptedToken = jwt(localStorage.jwtToken);
  const { Email, FirstName, Type, Id, IsActive } = decryptedToken;

  const newUser = {
    id: Id,
    email: Email,
    FirstName: FirstName,
    Type: Number(Type),
    isActive: IsActive,
  };
  store.dispatch({
    type: actionTypes.SET_USER,
    user: newUser,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
serviceWorkerRegistration.register();
