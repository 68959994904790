import * as actionTypes from './actionTypes';

const initialState = {
  user: null,
  alertMessage: '',
  msgType: 'success',
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.SET_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: action.message,
      };
    case actionTypes.SET_MSG_TYPE:
      return {
        ...state,
        msgType: action.msgType,
      };
    default:
      return {
        ...state,
      };
  }
};

export default rootReducer;
