import axios from "axios";

var AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};

AxiosInstance.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "jwtToken"
)}`;

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      localStorage.removeItem("jwtToken");
      clearCacheData();
      window.location = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

export default AxiosInstance;
